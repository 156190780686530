
<template>
    <div class="iletisimComp">
        <div id="iletisim"><br></div>
        <div id="iletisimAlt">
            <div class="mb-5">
                <h2 class="text-center main-title">İletişim</h2>
            </div>
            <div class="container">
                <div class="row me-0">
                    <div class="col-md-7 my-2">
                        <form class="">
                            <div class="row my-2">
                                <div class="col-md-4">
                                    <input type="text" class="form-control" placeholder="Adınız - Soyadınız"
                                        v-model="nameSur">
                                </div>
                                <div class="col-md-4">
                                    <input type="text" class="form-control" placeholder="Telefon Numarasınız"
                                        v-model="phoneNumb">
                                </div>
                                <div class="col-md-4">
                                    <input type="text" class="form-control" placeholder="E-Mail Adresiniz"
                                        v-model="mailAddress">
                                </div>
                            </div>
                            <div class="row my-2">
                                <div class="col">
                                    <textarea cols="30" rows="10" class="form-control" v-model="description"
                                        placeholder="Açıklama Giriniz.."></textarea>
                                </div>
                            </div>
                            <div class="row mx-0">
                                <button class="btn btn-success" @click="formGonder">Gönder</button>
                            </div>
                        </form>
                    </div>
                    <div class="col-md-5 my-2">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d764.5580154719632!2d32.77400992854968!3d39.95856402618149!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d3496f36558abf%3A0x14be45b3d9d958fe!2zQWxvZGVzdGVrIERhbsSxxZ9tYW5sxLFrIHZlIEJpbC4gVGVrLiBBLsWeLg!5e0!3m2!1str!2str!4v1691396040876!5m2!1str!2str"
                            height="350" style="border:0; width: 100%;" allowfullscreen="" loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "vue"
import { db } from "@/database/firebaseConfig"
import { collection, doc, setDoc } from "firebase/firestore";

export default {
    components: {},
    setup() {
        const nameSur = ref("")
        const phoneNumb = ref("")
        const mailAddress = ref("")
        const description = ref("")

        const temizle = () => {
            nameSur.value = ""
            phoneNumb.value = ""
            mailAddress.value = ""
            description.value = ""
        }

        const formGonder = () => {
            const dbConfig = collection(db, "contactForm")
            const document = doc(dbConfig)
            const docRef = {
                nameSur: nameSur.value,
                phoneNumb: phoneNumb.value,
                mailAddress: mailAddress.value,
                description: description.value,
            }
            setDoc(document, docRef)
            temizle()
            alert("Form başarıyla gönderildi")
        }

        return {
            nameSur, phoneNumb, mailAddress, description, formGonder
        }
    }

}
</script>

<style scoped>
.iletisimComp {
    background-image: linear-gradient(white, black);
}

#iletisim {
    margin-bottom: 80px;
}

#iletisimAlt {
    margin-bottom: 100px;
}

.main-title {
    font-size: 48px;
    color: #164D97;
    font-weight: bold;
}

.form-control {
    background-color: rgb(45, 52, 61);
    color: white;
}

.form-control:focus {
    color: white;
    background-color: rgb(45, 52, 61);
}

.form-control::placeholder {
    color: white;
    opacity: 0.8;
}
</style>
