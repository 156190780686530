<template>
    <div class="kvkk">
        <div class="modal fade" id="kvkkModal" tabindex="-1" aria-labelledby="kvkkModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="kvkkModalLabel">
                            KVKK Aydınlatma Metni
                        </h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <p><b>1. Veri Sorumlusu</b></p>
                        <p>Alodestek Danışmanlık ve Bilgi Teknolojileri ("Firma" olarak anılacaktır), 0850 309 80 21 ,
                            info@alodestek.com.tr ile temsil edilen bir veri sorumlusudur.
                        </p>
                        <p><b>2. Kişisel Verilerin İşlenme Amaçları</b></p>
                        <p>Firma, müşterileri, tedarikçileri ve çalışanları ile ilgili kişisel verileri aşağıdaki amaçlar
                            doğrultusunda işlemektedir:</p>
                        <ul class="kvkk-list">
                            <li>Müşteri ilişkilerinin yönetilmesi ve gerektiğinde iletişim kurulması</li>
                            <li>Ürün ve hizmetlerin sunulması, teslimatı ve faturalandırılması</li>
                            <li>Müşteri taleplerinin yerine getirilmesi ve destek sağlanması</li>
                            <li>İş sözleşmelerinin oluşturulması, yönetilmesi ve uygulanması</li>
                            <li>Tedarikçi ilişkilerinin yönetilmesi ve gerektiğinde iletişim kurulması</li>
                            <li>İş başvurularının alınması ve işe alım süreçlerinin yürütülmesi</li>
                            <li>İnsan kaynakları yönetimi ve personel dosyalarının tutulması</li>
                            <li>Yasal ve düzenleyici gereksinimlerin yerine getirilmesi</li>
                        </ul>
                        <p><b>3. Kişisel Verilerin Aktarılması</b></p>
                        <p>Firma, kişisel verilerin yalnızca yukarıda belirtilen amaçlar doğrultusunda ve ilgili yasal
                            düzenlemelere uygun olarak 3. kişilere aktarabilir. Bu aktarımlar, iş ortakları, hizmet
                            sağlayıcılar, yasal merciler veya yetkili kamu kurumları gibi ilgili taraflarla sınırlı
                            olacaktır.</p>
                        <p><b>4. Kişisel Veri Toplamanın Yöntemi ve Hukuki Sebebi</b></p>
                        <p>Firma, kişisel verileri, müşteri iletişim formları, sözleşmeler, iş başvuruları gibi çeşitli
                            kanallar aracılığıyla toplayabilir. Kişisel veri işleme faaliyetleri, sözleşme yürütme, yasal
                            yükümlülüklerin yerine getirilmesi veya meşru menfaatlerin korunması gibi hukuki sebeplere
                            dayanmaktadır.</p>
                        <p><b>5. Kişisel Veri Sahibinin Hakları</b></p>
                        <p>Kişisel veri sahipleri, KVKK kapsamında aşağıdaki haklara sahiptir:</p>
                        <ul class="kvkk-list">
                            <li>Kişisel veri işlenip işlenmediğini öğrenme</li>
                            <li>Kişisel verileri işlenmişse buna ilişkin bilgi talep etme</li>
                            <li>Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını
                                öğrenme</li>
                            <li>Kişisel verilerin yurt içinde veya yurt dışında aktarıldığı üçüncü kişileri bilme</li>
                            <li>Kişisel verilerin eksik veya yanlış işlenmişse düzeltilmesini isteme</li>
                            <li>Kişisel verilerin işlenmesini gerektiren sebeplerin ortadan kalkması halinde kişisel
                                verilerin silinmesini veya yok edilmesini isteme</li>
                            <li>Kişisel verilerin düzeltilmesi, silinmesi veya yok edilmesi halinde bu işlemlerin
                                kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme</li>
                            <li>Kişisel verilerin otomatik sistemler vasıtasıyla analiz edilmesi suretiyle aleyhinize bir
                                sonucun ortaya çıkmasına itiraz etme</li>
                            <li>Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğramanız halinde
                                zararın giderilmesini talep etme</li>
                        </ul>
                        <p><b>6. İletişim Bilgileri</b></p>
                        <p>KVKK ile ilgili talepleriniz için aşağıdaki iletişim bilgilerini kullanabilirsiniz:</p>
                        <p>Alodestek Danışmanlık ve Bilgi Teknolojileri A.Ş. Adres: Ergenekon mahallesi Savaştepe caddesi
                            23/3 Yenimahalle ANKARA Telefon: 0850 309 80 21 E-Posta: info@alodesstek.com.tr</p>
                        <p><b>7. Aydınlatma Metni Güncellemeleri</b></p>
                        <p>Firma, mevzuatta yapılacak değişikliklere ve faaliyetlerindeki değişikliklere bağlı olarak bu
                            aydınlatma metnini güncelleme hakkını saklı tutar. Güncellemeler web sitemizde yayınlanacaktır.
                        </p>
                        <p>Son Güncelleme: 09.08.2023</p>
                    </div>
                    <div class="modal-footer">
                        <input type="checkbox" class="form-check" id="kvkkForm">
                        <label for="kvkkForm">Okudum, anladım.</label>
                        <button type="button" class="btn btn-success" data-bs-dismiss="modal">Onayla</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>
/* .modal-dialog {
    width: 80% !important;
    margin: auto !important;
    max-width: 100% !important;
} */

.kvkk-list {
    list-style: disc;
}
</style>