import {initializeApp} from "firebase/app"
import {getFirestore} from "firebase/firestore"

const firebaseConfig = {
    apiKey: "AIzaSyD2nO1u5eav3XgDUbSeaQXAADe68wzhorM",
    authDomain: "alodestek-web.firebaseapp.com",
    projectId: "alodestek-web",
    storageBucket: "alodestek-web.appspot.com",
    messagingSenderId: "1049045469139",
    appId: "1:1049045469139:web:39e134326a06b100195126"
};

const app = initializeApp(firebaseConfig)
const db = getFirestore(app)

export {db}