<template>
    <div class="referanslarComp">
        <div id="referanslar"><br></div>
        <div id="referanslarAlt">
            <div class="mb-5">
                <h2 class="text-center main-title">Referanslar</h2>
            </div>
            <div class="container">
                <div class="row me-0 mx-auto referansList">
                    <div class="col-md-2 mx-md-2">
                        <img src="../assets/img/referans/DevatekBilgiTeknolojileri_black.png" class="referansImg" width="200">
                        <img src="../assets/img/referans/DevatekBilgiTeknolojileri_black.png" class="referansImg" width="200">
                        <img src="../assets/img/referans/DevatekBilgiTeknolojileri_black.png" class="referansImg" width="200">                        
                        <img src="../assets/img/referans/DevatekBilgiTeknolojileri_black.png" class="referansImg" width="200">
                    </div>
                    <div class="col-md-2 mx-md-2">
                        <img src="../assets/img/referans/DevatekBilgiTeknolojileri_black.png" class="referansImg" width="200">
                        <img src="../assets/img/referans/DevatekBilgiTeknolojileri_black.png" class="referansImg" width="200">
                        <img src="../assets/img/referans/DevatekBilgiTeknolojileri_black.png" class="referansImg" width="200">                        
                        <img src="../assets/img/referans/DevatekBilgiTeknolojileri_black.png" class="referansImg" width="200">
                    </div>
                    <div class="col-md-2 mx-md-2">
                        <img src="../assets/img/referans/DevatekBilgiTeknolojileri_black.png" class="referansImg" width="200">
                        <img src="../assets/img/referans/DevatekBilgiTeknolojileri_black.png" class="referansImg" width="200">
                        <img src="../assets/img/referans/DevatekBilgiTeknolojileri_black.png" class="referansImg" width="200">                        
                        <img src="../assets/img/referans/DevatekBilgiTeknolojileri_black.png" class="referansImg" width="200">
                    </div>
                    <div class="col-md-2 mx-md-2">
                        <img src="../assets/img/referans/DevatekBilgiTeknolojileri_black.png" class="referansImg" width="200">
                        <img src="../assets/img/referans/DevatekBilgiTeknolojileri_black.png" class="referansImg" width="200">
                        <img src="../assets/img/referans/DevatekBilgiTeknolojileri_black.png" class="referansImg" width="200">                        
                        <img src="../assets/img/referans/DevatekBilgiTeknolojileri_black.png" class="referansImg" width="200">
                    </div>
                    <div class="col-md-2 mx-md-2">
                        <img src="../assets/img/referans/DevatekBilgiTeknolojileri_black.png" class="referansImg" width="200">
                        <img src="../assets/img/referans/DevatekBilgiTeknolojileri_black.png" class="referansImg" width="200">
                        <img src="../assets/img/referans/DevatekBilgiTeknolojileri_black.png" class="referansImg" width="200">                        
                        <img src="../assets/img/referans/DevatekBilgiTeknolojileri_black.png" class="referansImg" width="200">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    components: {},
    setup() {

    }

}
</script>

<style scoped>
.referanslarComp {
    background-color: white;
}

#referanslar {
    margin-bottom: 80px;
}
#referanslarAlt{
    margin-bottom: 100px;
}
.referansImg{
    margin: 50px 0;
}
.main-title {
    font-size: 48px;
    color: #164D97;
    font-weight: bold;
}
.referansList{
    height: 400px;
    overflow: auto;
    margin: auto;
}
</style>