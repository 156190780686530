import { createRouter, createWebHistory } from 'vue-router'
import AnaSayfa from '../views/AnaSayfa.vue'
import Hizmetler from '@/views/Hizmetler.vue'
import Urunler from '@/views/Urunler.vue'
import Hakkimizda from '@/views/Hakkimizda.vue'
import Referanslar from '@/views/Referanslar.vue'
import Iletisim from '@/views/Iletisim.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: AnaSayfa
  },
  {
    path: '/hizmetlerimiz',
    name: 'hizmetler',
    component: Hizmetler
  },
  {
    path: '/urunlerimiz',
    name: 'urunler',
    component: Urunler
  },
  {
    path: '/hakkimizda',
    name: 'hakkimzda',
    component: Hakkimizda
  },
  {
    path: '/referanslar',
    name: 'referans',
    component: Referanslar
  },
  {
    path: '/iletisim',
    name: 'iletisim',
    component: Iletisim
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
