
<template>
  <Navbar/>
  <div class="app">
    <AnaSayfa/>
    <hizmetler/>
    <Urunler/>
    <Hakkimizda/>
    <!-- <Referanslar/> -->
    <Iletisim/>
    <Footer/>
    <GoTop/>
    <CookieAlert/>
    <!-- <Campaign/> -->
    <!-- <router-view /> -->
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue"
import AnaSayfa from "./views/AnaSayfa.vue";
import Urunler from './views/Urunler.vue';
import Hizmetler from './views/Hizmetler.vue';
import Hakkimizda from "./views/Hakkimizda.vue"
import Iletisim from "./views/Iletisim.vue";
import Referanslar from "./views/Referanslar.vue";
import Footer from "./views/Footer.vue"
import GoTop from "./components/GoTop.vue";
import CookieAlert from "./components/AnaSayfa/CookieAlert.vue";
import Campaign from "./components/AnaSayfa/Campaign.vue"
export default {
  name: 'HomeView',
  components: {
    Navbar, AnaSayfa, Hizmetler, Urunler, Hakkimizda, Iletisim, Footer, Referanslar, CookieAlert,
    GoTop, Campaign
  }
}
</script>

<style>
#app {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  background-color: black;
}
.app{
  display: flex;
  flex-direction: column;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgb(62, 62, 62);
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2);
}
</style>
