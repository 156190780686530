
<template>
    <div class="ayrıntıDiv text-white">
        <div class="container">
            <div class="row me-0">
                <div class="d-flex justify-content-between">
                    <div class="d-flex">
                        <div class="mx-4 mt-2">
                            <i class="bi bi-headset"></i>&nbsp;<span>0 850 309 80 21</span>
                        </div>
                        <div class="mx-4 mt-2">
                            <i class="bi bi-envelope-at-fill"></i>&nbsp;<a class="mail-link"
                                href="mailto:info@alodestek.com.tr">info@alodestek.com.tr</a>
                        </div>
                    </div>
                    <div class="row socialMedia gx-3 mt-2">
                        <div class="col">
                            <a href="https://twitter.com/alodestekas" class="socialLink" target="blank"><i
                                    class="bi bi-twitter socialMediaBtn"></i></a>
                        </div>
                        <div class="col">
                            <a href="https://www.facebook.com/alodestek/" class="socialLink" target="blank"><i
                                    class="bi bi-facebook socialMediaBtn"></i></a>
                        </div>
                        <div class="col">
                            <a href="https://www.instagram.com/alodestekas/" class="socialLink" target="blank"><i
                                    class="bi bi-instagram socialMediaBtn"></i></a>
                        </div>
                        <div class="col">
                            <a href="https://www.linkedin.com/company/alodestek-danışmanlık-ve-bilgi-teknolojileri-a-ş/about/"
                                class="socialLink" target="blank"><i class="bi bi-linkedin socialMediaBtn"></i></a>
                        </div>
                        <div class="col">
                            <a href="https://www.youtube.com/channel/UCKeRKsTS5hZd8Si7bAw4hlA" target="blank"
                                class="socialLink"><i class="bi bi-youtube socialMediaBtn"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <nav :class="navbarClass" style="transition: background-color 0.5s ease;">
        <div class="container-fluid">
            <a class="navbar-brand" href="#">
                <img src="../assets/img/logoSade.png" class="logoBrand">
                <span class="fontblue ms-1">Alo</span><span class="fontgreen">Destek</span>
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mx-auto mb-2 mb-lg-0">
                    <li v-for="(menu, index) in menuler" :key="index" class="nav-item text-center">
                        <a :href="menu.hrefId" class="nav-link" @click="indexNo()">{{ menu.adi }}</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
import { ref, onMounted, onBeforeUnmount, computed } from 'vue';

export default {
    setup() {
        const scrollThreshold = 350; // Transparan geçiş yüksekliği
        const scrolled = ref(false);
        const menuActive = ref(false)

        const menuler = ref([
            { index: 1, adi: "Hizmetler", hrefId: "#hizmetlerimiz", router: "/hizmetlerimiz" },
            { index: 2, adi: "Ürünler", hrefId: "#urunlerimiz", router: "/urunlerimiz" },
            { index: 3, adi: "Hakkımızda", hrefId: "#hakkimizda", router: "/hakkimizda" },
            // { index: 4, adi: "Referanslar", hrefId: "#referanslar", router: "/referanslar" },
            { index: 5, adi: "İletişim", hrefId: "#iletisim", router: "/iletisim" },
        ])

        const indexNo = () => {
            const nav = document.getElementById("navbarSupportedContent")
            nav.classList.toggle("show")
        }

        const handleScroll = () => {
            scrolled.value = window.scrollY > scrollThreshold;
        };

        onMounted(() => {
            window.addEventListener('scroll', handleScroll);
        });

        onBeforeUnmount(() => {
            window.removeEventListener('scroll', handleScroll);
        });

        return {
            navbarClass: computed(() =>
                scrolled.value
                    ? 'navbar navbar-expand-lg navbar-dark bg-dark sticky-top'
                    : 'navbar navbar-expand-lg navbar-dark bg-transparent sticky-top'
            ), indexNo, menuler, menuActive
        };
    },
};
</script>
<style scoped>
.ayrıntıDiv {
    background-color: #050e1677;
    height: 40px;
    display: flex;
}

@media only screen and (max-width: 600px) {
    .ayrıntıDiv {
        font-size: 12px;
    }

    .navbarMenu {
        position: relative;
    }

    .form-login {
        position: relative;
    }

    .logoBrand {
        width: 60px;
    }

    .fontblue {
        font-size: 20px !important;
        /* font-family: "Lucida Handwriting"; */
        /* text-shadow: 2px 2px 5px gray; */
        letter-spacing: 2px;
        font-weight: bold;
        font-size: 28px;
    }

    .fontgreen {
        font-size: 20px !important;
        /* text-shadow: 2px 2px 5px gray; */
        /* font-family: "Lucida Handwriting"; */
        letter-spacing: 2px;
        font-weight: bold;
        font-size: 28px;
    }

    .btnLogin {
        margin-top: 20px;
        margin-left: 20px;
    }


}

@media only screen and (max-width: 772px) {
    .socialMedia {
        display: none;
    }
}

.bg-offCanvas {
    background-color: black;
    opacity: 0.8;
}

.navbarMenu {
    display: flex;
    margin-left: 10%;
}

.logoBrand {
    max-height: 70px;
    margin: 0;
    padding: 0;
}

.navbar-brand {
    padding: 0 !important;
    margin: 0 !important;
    margin-left: 10% !important;
}

.fontblue {
    /* color: #0890c6; */
    /* font-family: "Lucida Handwriting";
    text-shadow: 2px 2px 5px gray; */
    letter-spacing: 2px;
    font-weight: bold;
    color: #00acee;
    font-size: 28px;
}

.fontgreen {
    color: #164D97;
    /* color: rgb(27, 110, 27); */
    /* text-shadow: 2px 2px 5px gray;
    font-family: "Lucida Handwriting"; */
    letter-spacing: 2px;
    font-weight: bold;
    font-size: 28px;
}

.nav-link {
    transition: all 0.2s !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    color: white !important;
}

.bg-dark {
    background-color: black !important;
    ;
}

.btnLogin {
    background-color: #00acee;
    color: white;
    padding: 5px 30px;
}

.socialMediaBtn {
    transition: 200ms;
    cursor: pointer;
}

.socialMediaBtn:hover {
    color: #0188bd;
}

.socialLink {
    color: white;
}

.mail-link {
    text-decoration: none;
    color: white;
}
</style>

