
<template>
    <div class="cookie-alert fixed-bottom mb-2" v-if="cookieActive">
        <div class="container py-2 bg-secondary cookie text-white">
            <div class="row me-0">
                <div class="col-md-12">
                    <div class="float-end">
                        <button class="btn btn-close" @click="hideCookies"></button>
                    </div>
                    <p>Size daha iyi hizmet sunabilmek amacıyla sitemizde çerezler kullanılmaktadır.
                        Ayrıntılı bilgiye Çerez Politikası ve Aydınlatma Metni'nden erişebilirsiniz. <br>
                        <a class="cookieLink" href="#cookieModal" data-bs-toggle="modal" data-bs-target="#cookieModal">Çerez
                            Politikası.</a>&nbsp;
                        <a class="cookieLink" href="#kvkkModal" data-bs-toggle="modal"
                            data-bs-target="#kvkkModal">Aydınlatma Metni</a>
                    </p>
                </div>
                <div class="row me-0 justify-content-end">
                    <div class="col-md-4">
                        <div class="d-flex float-end">
                            <a class="mx-2 btn btn-light" @click="hideCookies">Anladım</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from "vue"
export default {
    setup() {
        const cookieActive = ref(true)

        const hideCookies = () => {
            // cookieActive.value = !cookieActive.value
            cookieActive.value = false;
            localStorage.setItem("cookieConsent", "true");
        }

        onMounted(() => {
            const consent = localStorage.getItem("cookieConsent");
            if (consent === "true") {
                cookieActive.value = false;
            }
        });

        return { cookieActive, hideCookies,   }
    }
}
</script>

<style scoped>
.modalGoster {
    display: block;
}
.cookie {
    border-radius: 10px;
}

.cookieLink {
    color: rgb(190, 190, 190);
}

.cookieLink:hover {
    /* text-decoration: none; */
    color: #00acee;
}
</style>
