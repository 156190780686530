
<template>
    <div class="modal fade" id="hizmetForm" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
        aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="staticBackdropLabel">{{ urunAdi }}</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body text-dark">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="p-4 bg-light rounded">
                                <div class="product-logo">
                                    <div :class="imgSrc + ' ' + 'deneme'">
                                    </div>
                                </div>
                                <p>{{ aciklama }}</p>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <h4>Talep Formu</h4>
                            <p id="warnMsg" class="text-danger" v-if="warnMsg">{{ warning }}</p>
                            <form>
                                <div class="mb-3">
                                    <label for="adSoyad" class="form-label">Ad Soyad</label>
                                    <input type="text" class="form-control" id="adSoyad" v-model="adSoyad" required>
                                </div>
                                <div class="mb-3">
                                    <label for="telefon" class="form-label">Telefon</label>
                                    <input type="tel" class="form-control" id="telefon" v-model="telefon" required>
                                </div>
                                <div class="mb-3">
                                    <label for="email" class="form-label">E-mail</label>
                                    <input type="email" class="form-control" id="email" v-model="email" required>
                                </div>
                                <div class="mb-3">
                                    <label for="aciklama" class="form-label">Açıklama</label>
                                    <textarea class="form-control" id="aciklama" v-model="formAciklama" rows="4"
                                        required></textarea>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-success" id="btn-close" @click="formGonder">Gönder</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "vue"
import { db } from "@/database/firebaseConfig"
import { doc, setDoc, collection } from "firebase/firestore"


export default {
    props: ["urunAdi", "aciklama", "imgSrc"],
    setup(props) {
        const adSoyad = ref("")
        const telefon = ref("")
        const email = ref("")
        const formBaslik = ref("")
        const formAciklama = ref("")
        const formDate = ref("")
        const warnMsg = ref(false)
        const warning = ref("")

        const uyariGoster = () => {
            warnMsg.value = true
            warning.value = "Lütfen boş alanları doldurunuz"
        }
        const temizle = () => {
            adSoyad.value = ""
            telefon.value = ""
            email.value = ""
            formBaslik.value = ""
            formAciklama.value = ""
            formDate.value = ""
        }
        const modalKapat = () => {
            let kapat = document.getElementById("btn-close")
            kapat.toggleAttribute("data-bs-dismiss", "modal")
            kapat.toggleAttribute("aria-label", "Close")
        }

        const formGonder = () => {
            if (adSoyad.value == "" || telefon.value == "" || email.value == "" || formAciklama.value == "") {
                setTimeout(uyariGoster(), 3000)
            } else {
                warnMsg.value = false
                const date = new Date()
                formBaslik.value = props.urunAdi
                formDate.value = date.toLocaleString()
                const dbConfig = collection(db, "talepForm")
                const document = doc(dbConfig)
                const docRef = {
                    adSoyad: adSoyad.value,
                    telefon: telefon.value,
                    email: email.value,
                    formBaslik: formBaslik.value,
                    formAciklama: formAciklama.value,
                    formDate: formDate.value
                }
                setDoc(document, docRef)
                alert("Talebiniz başarıyla gönderildi..")
                temizle()
                modalKapat()
            }
        }



        return { formGonder, formBaslik, adSoyad, telefon, email, formAciklama, formDate, warnMsg, warning, uyariGoster, temizle, modalKapat }
    }
}
</script>

<style>
.product-logo {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 40px;
}

.alodestek {
    background-image: url("../../assets/img/hizmetList/alodestek.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.deneme {
    height: 100px;
    width: 100%;
}
</style>
