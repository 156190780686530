<template>
    <div class="urunlerComp">
        <div id="urunlerimiz"><br></div>
        <div id="urunlerimizAlt">
            <div class="mb-5">
                <h2 class="text-center main-title">Ürünlerimiz</h2>
            </div>
            <UrunList :urunler="urunler" />
        </div>
    </div>
</template>

<script>
import UrunList from '@/components/Urunler/UrunList.vue'
import { ref } from "vue"
export default {
    components: { UrunList },
    setup() {
        const urunler = ref([
            {
                indexNo: 0, urunAdi: "Unideva",
                aciklama: "Unideva muhasebe yazılımları, internetin olduğu her yerden kullanılabilen, verilerinizin bulut sunucularda güvenle muhafaza edildiği ön muhasebe ve resmi muhasebe bilgilerinizin güncel mevzuata uygun şekilde kayıt altına alınabildiği internet tabanlı muhasebe programlarıdır.", 
                className: "urun-0",
                imgSrc: "DevatekBilgiTeknolojileri_black"
            },
            {   indexNo: 1, urunAdi: "Unideva Portal", 
                aciklama: "Unideva muhasebe yazılımları, internetin olduğu her yerden kullanılabilen, verilerinizin bulut sunucularda güvenle muhafaza edildiği ön muhasebe ve resmi muhasebe bilgilerinizin güncel mevzuata uygun şekilde kayıt altına alınabildiği internet tabanlı muhasebe programlarıdır.", 
                className: "urun-0", 
                imgSrc: "DevatekBilgiTeknolojileri_black" 
            },
            {   indexNo: 2, urunAdi: "Aktif Pos", 
                aciklama: "Aktif POS Barkod Sistemleri olarak Barkod / Karekod Otomasyon Sistemlerinde çözüm odaklı, kolay kullanımlı özgün hizmetler sunarak iş verimliliğinizi artırmaktadır. Sektörel problemlere mühendislik disiplini içinde Ar-Ge çalışmaları yaparak profesyonel çözümler üretmekte ve geliştirdiği çözümlerde elektronik ve yazılımı en uygun şekilde entegre ederek verimli sonuçlar sunmaktadır. İhtiyaç duyan firmalara projelerinde bilgi (know-how) desteği de  vermektedir. Çözümlerimiz günün gereklerine uygun tüm masaüstü, web ve mobil platformlara uyarlanmaktadır.", 
                className: "urun-0", 
                imgSrc: "aktifpos" 
            },
            {   indexNo: 3, urunAdi: "MobilyaPlan", 
                aciklama: "Hayalinizdeki Mekanı Şimdi Tasarlayın! Programımız sayesinde evinizi, ofisinizi veya odanızı tasarlamak artık çok daha kolay! Mobilya tasarımlarını harita üzerine yerleştirerek mekanınızı özelleştirebilir, renkleri deneyebilir ve tasarımınızı 3D olarak görselleştirebilirsiniz. Hayallerinizi gerçeğe dönüştürmek için hemen deneyin!",
                className: "urun-0",
                imgSrc: "mobilyaplanLogo"
            },
        ])


        return {
            urunler
        }
    }

}
</script>

<style scoped>
.urunlerComp {
    background-color: white;
}

#urunlerimiz {
    margin-bottom: 80px;
}


.main-title {
    font-size: 48px;
    color: #164D97;
    font-weight: bold;
}
</style>