<template>
    <div class="hakkimizdaComp">
        <div id="hakkimizda"><br></div>
        <div id="hakkimizdaAlt">
            <div class="mb-5">
                <h2 class="text-center main-title">Hakkımızda</h2>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <img src="../assets/img/about.jpg" class="img-fluid">
                    </div>
                    <div class="col-md-5">
                        <h3 style="color: #164D97;">Tecrübenin yenilik yansıması.</h3>
                        <div class="mt-4" style="text-align: justify;">
                            <p class="fst-italic">
                                Alo Destek; mali müşavirler, KOBİ'ler ve bu sektörlere hizmet veren yazılım şirketleri için,
                                alanında uzman kadrosu ile, vergi mevzuatına uygun ve güncel bilgilerle teknik destek, servis ve
                                yazılımsal çözümler
                                üreten bir teknoloji ve yazılım şirketidir.</p>
                            <p class="fst-italic">Alo Destek uzun yıllardır edinilmiş bilgi birikim ve tecrübe üzerine inşa edilmiş bir
                                firmadır.</p>
                            <p class="fst-italic">
                                Günümüzde zamanın ve bilginin ne kadar değerli olduğunun farkındayız, bu sebeple hizmet verdiğimiz kişi
                                ve kurumların Dünya'nın neresinde olursa olsun
                                online ve telefon aracılığı ile hem eğitim hem de destek taleplerini kısa sürede ve doğru bilgi ile
                                karşılıyoruz.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    components: {},
    setup() {

    }

}
</script>

<style scoped>
.hakkimizdaComp {
    background-color: white;
}

#hakkimizda {
    margin-bottom: 80px;
}

.main-title {
    font-size: 48px;
    color: #164D97;
    font-weight: bold;
}
</style>