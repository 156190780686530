<template>
    <div class="col">
        <video src="../../assets/img/video.mp4" loop autoPlay muted class="homeVideo"></video>

    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.homeVideo {
    width: 80%;
    margin-top: 2.5rem;
    margin-left: 10%;
    margin-bottom: 50px;
    background-color: black;
}

@media only screen and (max-width: 600px) {
    .homeVideo {
        margin-top: 20px;
    }
}</style>