<template>
    <div>
        <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
            aria-labelledby="staticBackdropLabel" aria-hidden="true" :class="{ 'show': showModal }">
            <div class="modal-dialog modal-lg  modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            @click="closeModal"></button>
                    </div>
                    <div class="modal-body">
                        <img class="img-fluid" src="../../assets/img/kampanya/reklam.png" alt="">
                    </div>
                    <div class="modal-footer">
                        <div class="col">
                            <div class="d-flex float-start">
                                <input type="checkbox" class="form-check" id="modalLabel" v-model="checkboxValue">
                                <label for="modalLabel">&nbsp; Tekrar Gösterme</label>
                            </div>
                            <div class="d-flex float-end">
                                <a href="#hizmetlerimiz" class="nav-link">
                                    <button type="button" class="mx-1 btn btn-sm btn-success" @click="closeModal">
                                        Beni Oraya Götür
                                    </button>
                                </a>
                                <button type="button" class="mx-1 btn btn-sm btn-secondary" data-bs-dismiss="modal"
                                    @click="closeModal">Kapat</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from "vue"

export default {
    setup() {
        const showModal = ref(false)
        const checkboxValue = ref(false)

        const openModalProgrammatically = () => {
            showModal.value = true;
            document.body.classList.add("modal-open")
        }
        const closeModal = () => {
            showModal.value = false;
            document.body.classList.remove("modal-open")
            if (checkboxValue.value) {
                localStorage.setItem("campaignModalShown", "true");
            }

        }

        onMounted(() => {
            const campaignModalShown = localStorage.getItem("campaignModalShown");
            if (!campaignModalShown) {
                setTimeout(() => {
                    openModalProgrammatically()
                }, 2000)
            }
        })

        return {
            showModal, openModalProgrammatically, closeModal, checkboxValue
        }

    }
};
</script>

<style>
.modal {
    display: none;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal.show {
    display: block;
}

.modal-lg {
    --bs-modal-width: 700px !important;
}</style>
