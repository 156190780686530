<template>
    <div>
        <button v-if="showButton" @click="goToTop" class="go-to-top btn btn-primary">
            <b class="bi bi-chevron-double-up"></b>
        </button>
    </div>
</template>
 
<script>
import { ref, onMounted, onBeforeUnmount } from 'vue';

export default {
    setup() {
        const showButton = ref(false);

        const handleScroll = () => {
            showButton.value = window.pageYOffset > 200;
        };

        const goToTop = () => {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        };

        onMounted(() => {
            window.addEventListener("scroll", handleScroll);
        });

        onBeforeUnmount(() => {
            window.removeEventListener("scroll", handleScroll);
        });

        return {
            showButton,
            goToTop,
        };
    },
};
</script>

<style>
.go-to-top {
    position: fixed;
    bottom: 20px;
    right: 10px;
    font-size: large;
    font-weight: bold;
    border: none;
}
.btn-primary{
    background-color: #164D97 !important;
    color: white !important;
}
</style>