<template>
    <div class="kvkk">
        <div class="modal fade" id="cookieModal" tabindex="-1" aria-labelledby="cookieModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="cookieModalLabel">
                            Çerez Politikası
                        </h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <p><b>1. Çerezler Hakkında Bilgi</b></p>
                        <p>Bu Çerez Politikası, Alodestek Danışmanlık ve Bilgi Teknolojileri ("Firma" olarak anılacaktır)
                            web sitesi üzerinde kullanılan çerezlerle ilgili bilgi vermek amacıyla hazırlanmıştır. Çerezler,
                            web sitesini kullanırken cihazınıza kaydedilen küçük metin dosyalarıdır. Bu çerezler, web
                            sitesinin daha etkin çalışmasını sağlamak ve kullanıcı deneyiminizi geliştirmek amacıyla
                            kullanılmaktadır.
                        </p>
                        <p><b>2. Çerez Kullanım Amaçları</b></p>
                        <p>Firma, aşağıdaki amaçlar doğrultusunda çerez kullanmaktadır:</p>
                        <ul class="kvkk-list">
                            <li>Kullanıcı oturumlarını yönetmek ve kullanıcıların kimlik doğrulamasını sağlamak</li>
                            <li>Web sitesinin performansını ve kullanıcı deneyimini geliştirmek</li>
                            <li>Ziyaretçi tercihlerini hatırlamak ve tercihlere uygun içerik sunmak</li>
                            <li>Hedeflenmiş reklamcılık ve pazarlama faaliyetlerini desteklemek</li>
                            <li>Web sitesi trafiği ve etkileşimlerini analiz etmek ve izlemek</li>
                        </ul>
                        <p><b>3. Hangi Tür Çerezler Kullanılır?</b></p>
                        <p>Firma web sitesinde aşağıdaki türde çerezleri kullanabilir:</p>
                        <ul class="kvkk-list">
                            <li>Zorunlu Çerezler: Web sitesinin temel işlevlerini yerine getirebilmek için gereklidir.
                                Kullanıcı oturumlarını yönetmek, güvenliği sağlamak gibi işlevleri yerine getirirler.</li>
                            <li>Performans Çerezleri: Web sitesinin performansını ve kullanıcı deneyimini geliştirmek için
                                kullanılır. Bu çerezler, site kullanım istatistiklerini toplar ve analiz eder.</li>
                            <li>İşlevsellik Çerezleri: Ziyaretçi tercihlerini hatırlamak amacıyla kullanılır. Örneğin,
                                kullanıcı tercihlerini kaydeden veya oturum açma bilgilerini hatırlayan çerezler bu
                                kategoriye girer.</li>
                            <li>Hedefleme ve Reklam Çerezleri: Ziyaretçiye özel içerik sunmak ve hedeflenmiş reklamlar
                                göstermek amacıyla kullanılır.</li>
                        </ul>
                        <p><b>4. Çerezleri Yönetme ve Reddetme</b></p>
                        <p>Çerez kullanımını kabul etmek veya reddetmek size bağlıdır. Tarayıcı ayarlarınızı düzenleyerek
                            çerez kullanımını kontrol edebilirsiniz. Ancak, zorunlu çerezler olmadan web sitemizin bazı
                            özellikleri düzgün çalışmayabilir. Tarayıcı ayarlarınızı değiştirerek çerez kullanımını nasıl
                            yönetebileceğinizi öğrenmek için tarayıcınızın yardım dokümantasyonunu inceleyebilirsiniz.</p>
                        <p><b>5. Çerez Politikası Güncellemeleri</b></p>
                        <p>Firma, çerez politikasını mevzuat değişikliklerine, web sitesinin yapısına veya işleyişine bağlı
                            olarak güncelleyebilir. Bu nedenle, çerez politikasında yapılan güncellemeleri düzenli olarak
                            kontrol etmeniz önerilir.
                        </p>
                        <p>Son Güncelleme: 09.08.2023</p>
                    </div>
                    <div class="modal-footer">
                        <input type="checkbox" class="form-check" id="cookieForm">
                        <label for="cookieForm">Okudum, anladım.</label>
                        <button type="button" class="btn btn-success" data-bs-dismiss="modal">Onayla</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>
/* .modal-dialog {
    width: 80% !important;
    margin: auto !important;
    max-width: 100% !important;
} */

.kvkk-list {
    list-style: disc;
}</style>