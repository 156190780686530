<template>
  <div class="home">
    <HomeVideo/>
  </div>
</template>

<script>
// @ is an alias to /src
import HomeVideo from '@/components/AnaSayfa/HomeVideo.vue';
export default {
  name: 'HomeView',
  components: {
    HomeVideo
  }
}
</script>
<style>
.home{
  background-color: black;
}
</style>
