
<template>
    <div class="container-fluid">
        <div class="listDiv">
            <div v-for="(item, index) in urunler" :key="index" :style="getStyle(index)" @click="toggleHeight(index)"
                :class="[item.className + (index + 1), 'list-div', { active: activeIndex !== index }]">
                <div>
                    <div class="card-title">
                    </div>
                    <h2 class="list-title">{{ item.urunAdi }}</h2>
                    <!-- <div class="card-text">
                        <p class="list-desc">{{ item.aciklama }}</p>
                    </div> -->
                </div>
                <div class="cardDiv">
                    <button class="cardBtn btn btn-success" data-bs-toggle="modal" data-bs-target="#urunForm"
                        @click="showBaslik(item.urunAdi, item.aciklama, item.imgSrc)">
                        Detaylı Bilgi</button>
                </div>
            </div>
        </div>
    </div>
    <UrunForm :urunAdi="selectedUrun" :aciklama="selectedAciklama" :imgSrc="selectedImg" />
</template>

<script>
import { reactive, onMounted, onBeforeUnmount, ref } from 'vue';
import UrunForm from './UrunForm.vue';
export default {
    components: { UrunForm },
    props: ["urunler"],
    setup(props) {
        const state = reactive({
            activeIndex: 0,
            isMobile: false,
        });

        const selectedUrun = ref(null);
        const selectedAciklama = ref(null);
        const selectedImg = ref(null);

        const showBaslik = (urun, aciklama, img) => {
            selectedUrun.value = urun;
            selectedAciklama.value = aciklama
            selectedImg.value = img
        }

        const checkIsMobile = () => {
            state.isMobile = window.innerWidth < 800;
        };

        const getStyle = (index) => {
            const width = state.isMobile ? '100%' : state.activeIndex === index ? '600px' : '250px';
            const height = state.isMobile ? (state.activeIndex === index ? '500px' : '200px') : '700px';
            return { width, height };
        };

        const toggleHeight = (index) => {
            state.activeIndex = index
        };

        onMounted(() => {
            checkIsMobile();
            window.addEventListener('resize', checkIsMobile);
        });

        onBeforeUnmount(() => {
            window.removeEventListener('resize', checkIsMobile);
        });

        return {
            ...state,
            getStyle,
            toggleHeight,
            showBaslik,
            selectedUrun, selectedAciklama, selectedImg
        };
    },
};
</script>

<style scoped>
@media (max-width: 768px) {
    .card-title {
        height: 100px !important;
    }
}

@media (min-width: 769px) {
    .card-title {
        height: 0 !important;
    }
}

.card-title {
    background-color: rgb(209, 209, 209);
    height: 100px;
    opacity: 0.8;
}

.btn-success {
    background-color: #164D97;
    border-color: #164D97;
}

.btn-success:hover {
    background-color: #1470B4;
}

.list-div {
    position: relative;
    display: inline-block;
    cursor: pointer;
    transition: width 0.5s, height 0.5s;
    border-radius: 10px;
    font-size: 16px;
    color: white;
    justify-content: center;
    align-items: center;
    /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    margin: 10px;
}

.list-content {
    padding: 0.2rem 1rem;
    height: 250px;
    text-align: justify;
}

.cardDiv {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 20px;
}

.card-text {
    padding: 0.2rem 1rem;
    height: 250px;
    text-align: justify;
}


.list-div:focus {
    outline: none;
}


.listDiv {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}


.urun-01 {
    background: url("https://www.devatek.com/wp-content/uploads/2021/08/Unideva_Desktop_Default.webp");
    background-size: cover;
    background-position: center;
    margin: 10px;
}

.urun-02 {
    background: url("../../assets/img/urunler/portal.png");
    background-size: cover;
    background-position: center;
    margin: 10px;
}

.urun-03 {
    background: url("../../assets/img/urunler/restoran-pos1-.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    margin: 10px;
}

.urun-04 {
    background: url("../../assets/img/urunler/mobilyaplan.png");
    margin: 10px;
    background-repeat: no-repeat;
    background-size: cover;
}

.list-title {
    position: absolute;
    top: 0;
    color: #164D97;
    text-align: center;
    margin: 20px 10px 10px 10px;
}

.list-desc {
    text-align: justify;
    margin: 0rem 0rem 1rem 0rem;
    font-size: 20px;
    height: 200px;
}</style>
