<template>
    <div class="footer mt-3">
        <footer class="w-100 py-4 flex-shrink-0">
            <div class="container py-4">
                <div class="row gy-4 gx-5 me-0">
                    <div class="col-lg-4 col-md-6">
                        <h5 class="h4 text-white">
                            Alo Destek Yazılım ve Bilgi Teknolojileri AŞ
                        </h5>
                        <p class="small text-white">Çamlıca, 145. Sk. No:6, 06200 Yenimahalle/Ankara</p>
                        <p class="small text-white mb-0">&copy; Her Hakkı Saklıdır. <a class="text-primary"
                                href="#">www.alodestek.com.tr</a></p>
                    </div>
                    <div class="col-lg-2 col-md-6">
                        <h5 class="text-white mb-3">Faydalı linkler</h5>
                        <ul class="list-unstyled text-muted">
                            <li><a target="blank" href="https://www.devatek.com">Devatek</a></li>
                            <li><a target="blank" href="https://www.gib.gov.tr">GİB</a></li>
                            <li><a target="blank" href="https://intvrg.gib.gov.tr/">İnternet Vergi Dairesi</a></li>
                            <li><a target="blank" href="https://www.turkiye.gov.tr/">E-Devlet</a></li>
                        </ul>
                    </div>
                    <div class="col-lg-2 col-md-6">
                        <h5 class="text-white mb-3">Sosyal Medya</h5>
                        <ul class="list-unstyled">
                            <li><a target="blank" href="https://twitter.com/alodestekas"><i class="bi bi-twitter socialMediaBtn"></i> Twitter (X)</a></li>
                            <li><a target="blank" href="https://www.facebook.com/alodestek/"><i class="bi bi-facebook socialMediaBtn"></i> Facebook</a></li>
                            <li><a target="blank" href="https://www.instagram.com/alodestekas/"><i class="bi bi-instagram socialMediaBtn"></i> İnstagram</a></li>
                            <li><a target="blank" href="https://www.linkedin.com/company/alodestek-danışmanlık-ve-bilgi-teknolojileri-a-ş/about/"><i class="bi bi-linkedin socialMediaBtn"></i> LinkedIn</a></li>
                            <li><a target="blank" href="https://www.youtube.com/channel/UCKeRKsTS5hZd8Si7bAw4hlA"><i class="bi bi-youtube socialMediaBtn"></i> Youtube</a></li>
                        </ul>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <!-- <p class="small text-white">Bültenimize Üye Olun..</p>
                        <form @submit.prevent="mailGonder">
                            <div class="input-group mb-3">
                                <input class="form-control" type="email" aria-describedby="button-addon2" id="mailGonderInp"
                                    v-model="emailAdress">
                                <button class="btn btn-primary" id="button-addon2" type="submit">
                                    <i class="bi bi-envelope-fill"></i></button>
                            </div>
                        </form> -->
                        <h5 class="text-white mb-3">Destek</h5>
                        <ul class="list-unstyled">
                            <li><a href="https://www.devatek.com/download/AnyDesk.exe">AnyDesk</a></li>
                            <li><a href="https://download.teamviewer.com/download/TeamViewerQS.exe">TeamViewer</a></li>
                        </ul>
                        <h5 class="text-white mb-3">Mevzuat</h5>
                        <ul class="list-unstyled">
                            <li><a href="#kvkkModal" data-bs-toggle="modal" data-bs-target="#kvkkModal">KVKK</a></li>
                            <li><a href="#cookieModal" data-bs-toggle="modal" data-bs-target="#cookieModal">Çerez Politikası</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
        <KVKK/>
        <Cookies/>
    </div>
</template>

<script>
import { ref } from "vue"
import KVKK from '@/components/Footer/KVKK.vue'
import Cookies from "@/components/Footer/Cookies.vue"
export default {
    components: {KVKK, Cookies},
    setup() {
        const emailAdress = ref("")
        const mailGonder = () => {
            let inpVal = document.getElementById("mailGonderInp")
            if (emailAdress.value) {
                alert("Mailiniz adresiniz başarıyla iletildi..")
            }
            else {
                inpVal.classList.add("is-invalid")
            }
        }

        return { mailGonder, emailAdress }
    }
}
</script>

<style scoped>
a {
    color: white;
    text-decoration: none;
    transition: all 0.3s;
}
</style>

