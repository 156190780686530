
<template>
    <div class="hizmetlerComp">
        <div id="hizmetlerimiz"><br></div>
        <div id="hizmetlerimizAlt">
            <div class="mb-5">
                <h2 class="text-center main-title">Hizmetlerimiz</h2>
            </div>
            <HizmetList :hizmetler="hizmetler" />
        </div>
    </div>
</template>

<script>
import HizmetList from '@/components/Hizmetler/HizmetList.vue'
import { ref } from "vue"
export default {
    components: { HizmetList },
    setup() {
        const hizmetler = ref([
            { 
                indexNo: 0, urunAdi: "Çağrı Merkezi Danışmanlığı", 
                aciklama: "Müşteri memnuniyeti ve etkili iletişim çağrı merkezlerinin başarısının anahtarıdır. Çağrı merkezi danışmanlığı hizmetimizle, işletmenizin müşteri hizmetlerini daha verimli, etkili ve memnuniyet odaklı hale getirin. Alanında uzman danışmanlarımız, çağrı merkezi süreçlerinizi analiz ederek özelleştirilmiş çözümler sunar. Müşteri deneyimini en üst düzeye çıkarmak ve işletmenizin büyümesine katkı sağlamak için hemen bizimle iletişime geçin.", 
                className: "hizmet-0" ,
                imgSrc: "alodestek"
            },
            { 
                indexNo: 1, urunAdi: "E-Belge Dizayn (karekod)", 
                aciklama: "İlk izlenim her zaman önemlidir, özellikle de belgeleriniz iş dünyasında. E-belge dizaynı hizmetimizle, sunumlarınızı ve dokümanlarınızı sadece anlaşılır değil, aynı zamanda etkileyici ve profesyonel bir şekilde sunun. Özelleştirilmiş tasarımlarımız sayesinde markanızın kimliğini yansıtacak, okunabilirliği artıracak ve izleyicilerinizi etkileyecek belgeler oluşturabilirsiniz. Belgelerinizin gücünü keşfedin, hemen bize katılın.", 
                className: "hizmet-0",
                imgSrc: "alodestek" 
            },
            {
                indexNo: 2, urunAdi: "Müşteri Hizmetleri Danışmanlığı", 
                aciklama: "Müşteri memnuniyeti, işletmenizin başarısının temel taşıdır. Müşteri hizmetleri danışmanlığı hizmetimizle, işletmenizin müşteri ilişkilerini güçlendirin, hizmet kalitesini yükseltin ve sadık bir müşteri kitlesi oluşturun. Uzman danışmanlarımız, etkili iletişim stratejileri, problem çözme yöntemleri ve müşteri odaklı yaklaşımlar konusunda size rehberlik ederek işletmenizin müşteri deneyimini mükemmelleştirmenize yardımcı olacaktır. Müşterilerinizle daha sıkı bağlar kurmak ve rekabette öne çıkmak için bizimle iletişime geçin.", 
                className: "hizmet-0",
                imgSrc: "alodestek" 
            },
            { 
                indexNo: 3, urunAdi: "Satış Yönetimi Danışmanlığı", 
                aciklama: "Başarılı bir işletmenin temel taşı etkili satış stratejileridir. Satış yönetimi danışmanlığı hizmetimizle, satış ekiplerinizi daha verimli hale getirin, satış süreçlerinizi optimize edin ve gelirlerinizi artırın. Uzman danışmanlarımız, müşteri ihtiyaçlarını anlama, pazarlama stratejileri geliştirme ve satış tekniklerini geliştirme konusunda size rehberlik ederek işletmenizin satış performansını en üst düzeye çıkarmak için yardımcı olacaktır. Satışlarınızı yükseltmek ve rekabette öne çıkmak için bizimle iletişime geçin.", 
                className: "hizmet-0",
                imgSrc: "alodestek" 
            },
        ])

        return { hizmetler }
    }

}
</script>

<style scoped>
.hizmetlerComp {
    background-image: linear-gradient(black, white);
}

#hizmetlerimiz {
    margin-bottom: 80px;
}

.main-title {
    font-size: 48px;
    color: #164D97;
    font-weight: bold;
}
</style>
